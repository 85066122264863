<template>
  <div class="van-main storage-list">
    <van-search
      v-model="searchForm.cabinetNoOrName"
      class="van-main-search"
      shape="round"
      left-icon=""
      show-action
      placeholder="可输入委托客户/货物名称进行搜索"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">
          搜索
        </div>
      </template>
    </van-search>
    <div class="van-refresh-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad"
        >
          <div v-for="item in list" :key="item.id" class="storage-item">
            <div>
              <p><span>委托客户</span> <span>{{ item.enterpriseName }}</span></p>
              <p><span>货物名称</span><span>{{ item.cargoName }}</span></p>
              <p><span>账面件数</span><span>{{ item.pieceNumber }}</span></p>
              <p><span>账面重量（KG）</span><span>{{ utils.moneyFormat(item.weight) }}</span></p>
              <p><span>仓库名称</span><span>{{ item.warehouseName }}</span></p>
              <van-button plain round size="small" type="info" native-type="button" @click="detail(item.stockId)">
                查看详情
              </van-button>
            </div>
            <van-divider />
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <back-button />
  </div>
</template>

<script>
import BackButton from '@/components/back-button'
import { Dialog, Cell, CellGroup, Search, List, Divider, Button, PullRefresh } from 'vant'
export default {
  components: {
    BackButton,
    [Dialog.Component.name]: Dialog.Component,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [List.name]: List,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [PullRefresh.name]: PullRefresh
  },
  data () {
    return {
      searchForm: {
        storageNumber: '',
        pageNum: 1,
        pageSize: 10
      },
      loading: false,
      finished: false,
      refreshing: false,
      finishedText: '没有更多了',
      tableList: {
        list: []
      },
      list: []
    }
  },
  created () {
  },
  methods: {
    onSearch () {
      this.searchForm.pageNum = 1
      this.finishedText = '没有更多了'
      this.getList()
    },
    getList () {
      this.api.stock.listPage(this.searchForm).then(result => {
        this.tableList = result.data.value
        if (this.tableList.pages === 0 && this._.isEmpty(this.tableList.list)) {
          this.list = []
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          if (this.tableList.pageNum === 1) {
            this.list = result.data.value.list
          } else {
            this.list = this.list.concat(result.data.value.list)
          }
          this.loading = false
          if (this.tableList.pageNum < this.tableList.pages) {
            this.searchForm.pageNum += 1
          } else {
            this.finished = true
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      }).finally(() => {
        this.refreshing = false
      })
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      this.loading = true
      this.searchForm.pageNum = 1
      this.getList()
    },
    onLoad () {
      this.getList()
    },
    detail (id) {
      this.$router.push({ name: 'storageDetail', query: { id: id } })
    }
  }
}
</script>

<style lang="less" scoped>
.storage-list {
  .van-list {
    margin-top: 10px;
    margin-bottom: 50px;
  }
  .storage-item {
    font-size: 14px;
    margin-top: 10px;
    position: relative;
    p {
      margin-left: 10px;
      >span {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        vertical-align: middle;
      }
      >span:first-child {
        width: 120px;
        text-align: right;
      }
      >span:nth-child(2) {
        margin-left: 15px;
        overflow: hidden;
      }
    }
    button {
      position: absolute;
      height: 30px;
      top: calc(50% - 15px);
      right: 15px;
    }
    .van-divider {
      margin-top: 5px;
    }

  }
}

</style>
